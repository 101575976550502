@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
body{
  font-family: "VT323", monospace;
  background-color: #000;
  color: #fff;
}
.score{
  color: #A525D3;
}
